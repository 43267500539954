import React from "react"
import { graphql } from "gatsby"

import SEO from "src/components/Element/seo"
import "./index.css"

const About = ({data, location}) => {
    const siteTitle = data.site.siteMetadata.title
    const config = data.allStrapiConfig.edges[0].node

    return (
        <>
            <SEO
                title={"お問い合わせ - " + siteTitle}
                description={"お問い合わせページ。" + config.site_description}
            />
            <div id="contact" className="main-content">
                <div className="article-main">
                    <div className="am-wrapper">
                        <div className="page-content">
                            <h1 className="page-content-title">お問い合わせ</h1>
                            <div className="page-content-text">
                                <p>
                                    お問い合わせ先<br />
                                    <a href={"mailto:" + config.mail_address}>{ config.mail_address }</a>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allStrapiConfig {
            edges {
                node {
                    mail_address,
                    site_description
                }
            }
        }
    }
`
